<template>
  <div>
    <div>
      <!-- 主視覺 -->
      <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/MB-kv.jpg" alt="試用包介紹頁面" class="img-fluid d-lg-none">
      <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/PC-kv.jpg" alt="試用包介紹頁面" class="img-fluid d-none d-lg-block">
      <div class="container-card pb-4">
        <!-- 商品介紹 -->
        <div ref="prdIntroduciton" class="pt-4 pt-lg-5">
          <div>
            <!-- 潔顏粉 介紹 -->
            <div :class="{ active: activeTab === 'washpowder', show: activeTab === 'washpowder' }">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_01.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_02.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_03.jpg" alt="潔顏粉" class="img-fluid">
              <!-- 官網影片 -->
              <div class="bg-introduction px-3 pb-3 pb-lg-5 px-lg-5">
                <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_04.png" alt="潔顏粉" class="img-fluid">
                <div class="row gx-1">
                  <div class="col-lg-3 mb-2 mb-lg-0">
                    <p class="fs-7 fs-lg-6">
                      38年前，Papawash成立實驗室，投入專業研發，採用大自然原有的力量，取材製成木瓜酵素潔顏粉，能溫和洗去累積的老廢角質，深入毛孔去除多餘油脂及髒污，卻不傷肌膚，且不帶走肌膚原有水分；以最自然溫和方式喚醒酵素力量，讓肌膚經由一次一次的洗顏及角質護理，回到健康的美麗，這是 Papawash 一直以來秉持的品牌信念。
                    </p>
                  </div>
                  <div class="col-lg-9 text-center">
                    <!-- YT 嵌入 -->
                    <div v-if="video.src==='yt'" class="ratio ratio-16x9 mb-4">
                      <iframe class="w-100" :src="video.url" autoplay="autoplay" frameborder="0"></iframe>
                    </div>
                    <!--  mp4 播放 -->
                    <div v-if="video.src==='aws'" class="ratio ratio-16x9 mb-4">
                      <video id="vid" class="w-100" loop="true" controls muted playsinline>
                        <source :src="`${video.url}#t=0.001`" type="video/mp4" />
                      </video>
                    </div>
                    <router-link to="/aboutus" class="btn btn-primary rounded-0 bg-primary py-1 px-10 py-lg-2">了解更多</router-link>
                  </div>
                </div>
              </div>
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_05.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_06a.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_06b.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_07.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_08.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_09.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_10.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_11.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_12m.jpg" alt="潔顏粉" class="img-fluid d-block d-lg-none">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_12.jpg" alt="潔顏粉" class="img-fluid d-none d-lg-block">
              <!-- 好評推薦 -->
              <div class="bg-introduction px-2 pb-4 pb-lg-5">
                <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_13.png" alt="潔顏粉" class="img-fluid">
                <div class="row justify-content-center">
                  <div class="col-10 col-md-8 col-lg-6">
                    <div class="position-relative">
                      <swiper
                        class="swiperComment"
                        :loop="true"
                        :spaceBetween="0"
                        :initialSlide="0"
                        :navigation="{
                          nextEl: '#js-next1',
                          prevEl: '#js-prev1'
                        }"
                      >
                        <swiper-slide class="text-center">
                          <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_13_jp_person_01.jpg" alt="好評推薦" class="img-fluid mb-3 mb-lg-4">
                          <p class="fw-medium fs-lg-5">從學生時代就開始使用了，一直用到現在還非常適合我！真的是不分年齡~</p>
                        </swiper-slide>
                        <swiper-slide class="text-center">
                          <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_13_jp_person_03.jpg" alt="好評推薦" class="img-fluid mb-3 mb-lg-4">
                          <p class="fw-medium fs-lg-5">小時候就開始使用了！</p>
                          <p class="fw-medium fs-lg-5">一直使用著Papawash，完全離不開!</p>
                        </swiper-slide>
                        <swiper-slide class="text-center">
                          <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_13_jp_person_02.jpg" alt="好評推薦" class="img-fluid mb-3 mb-lg-4">
                          <p class="fw-medium fs-lg-5">洗完後皮膚光滑的讓我感動，照鏡子也非常滿意</p>
                        </swiper-slide>
                      </swiper>
                      <!-- navigation buttons -->
                      <div id="js-prev1" class="swiper-button-prev"></div>
                      <div id="js-next1" class="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_14.jpg" alt="潔顏粉" class="img-fluid">
              <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/papaevent/trials/classic_15.jpg" alt="潔顏粉" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkVideoSrc from '@/assets/js/checkVideoSrc'
import BannerSvc from '@/service/banner-service.js'

export default {
  data () {
    return {
      activeTab: 'washpowder',
      video: {}
    }
  },
  methods: {
    getVideo () {
      BannerSvc.GetEventBanner('Papa_Video')
        .then((SvcData) => {
          this.video = checkVideoSrc(SvcData[0].targetUrl)
        })
    }
  },
  mounted () {
    this.getVideo()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_trialintroduce.scss';
</style>
